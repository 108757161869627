import React from 'react';
import { graphql } from 'gatsby';
import { SEO } from '../../components/SEO';
import Layout from '../../components/layout';
import GetServiceItems from '../../components/getServiceItems';
import bg from '../../images/bg_image_trainings_page.jpg';
import logo from '../../images/Logo@2x.png';

export default props => {
  const { data, location } = props;
  const courseList = data.allPagecontentJson;
  const allCourses = data.allCoursesJson;

  return (
    <>
      <SEO
        title="Excel-kurssit ja koulutus"
        description="Tutustu excel-kurssivalikoimaamme. Meiltä löydät sopivan koulutuksen sekä perustaitojen oppimiseen että excel-pivot ja excel-makroaiheisiin."
      />
      <Layout
        mainTitle={'Excel-kurssit ja koulutus'}
        mainTitleClasses={'mt-3'}
        additionalTitle={'Yrityksellesi räätälöitynä'}
        headerBgStyles={{ background: 'url(' + bg + ')' }}
        headerTitleStyle={{ color: '#0086ef' }}
        headerAdditionalTitleStyle={{ color: '#019999' }}
        additionalTitleClassNames={'mb-3 mt-md-0 mb-md-3'}
        headerNavLinkStyle={{ color: '#7a7c81' }}
        logo={logo}
        {...props}
      >
        <div className="services">
          <GetServiceItems
            items={courseList.nodes[0].service_list[0].items}
            allCourses={allCourses}
          />
        </div>
      </Layout>
    </>
  );
};

export const query = graphql`
  query MyQuery4 {
    allPagecontentJson {
      nodes {
        service_list {
          items {
            cta
            description
            id
            title
          }
          service_group_title
          service_group_id
          scroll_to_id
        }
        info_at_top {
          topics {
            link
            title
          }
        }
      }
    }
    allCoursesJson {
      edges {
        node {
          id
          url_path
        }
      }
    }
  }
`;
